define("discourse/plugins/discourse-github-verification/discourse/templates/connectors/user-post-names/github-verified-username", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if
    (and
      this.site.github_verification_enabled
      this.model.custom_fields.github_verified_username
    )
  }}
    <GithubUserLink
      @username={{this.model.custom_fields.github_verified_username}}
    />
  {{/if}}
  */
  {
    "id": "nNzDNyF1",
    "block": "[[[41,[28,[37,1],[[30,0,[\"site\",\"github_verification_enabled\"]],[30,0,[\"model\",\"custom_fields\",\"github_verified_username\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@username\"],[[30,0,[\"model\",\"custom_fields\",\"github_verified_username\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"github-user-link\"]]",
    "moduleName": "discourse/plugins/discourse-github-verification/discourse/templates/connectors/user-post-names/github-verified-username.hbs",
    "isStrictMode": false
  });
});