define("discourse/plugins/discourse-github-verification/discourse/templates/connectors/user-preferences-nav/user-nav__preferences-github-verification", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.github_verification_enabled}}
    <LinkTo @route="preferences.github">
      {{d-icon "fab-github"}}
      <span>{{i18n "discourse_github_verification.github"}}</span>
    </LinkTo>
  {{/if}}
  */
  {
    "id": "sg7yGPPE",
    "block": "[[[41,[30,0,[\"site\",\"github_verification_enabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\"],[\"preferences.github\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"fab-github\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"discourse_github_verification.github\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-github-verification/discourse/templates/connectors/user-preferences-nav/user-nav__preferences-github-verification.hbs",
    "isStrictMode": false
  });
});