define("discourse/plugins/discourse-github-verification/discourse/routes/preferences-github", ["exports", "discourse/lib/utilities", "discourse/routes/restricted-user"], function (_exports, _utilities, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesChatRoute extends _restrictedUser.default {
    beforeModel() {
      if (!this.site.github_verification_enabled) {
        return this.router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
      }
    }
  }
  _exports.default = PreferencesChatRoute;
});